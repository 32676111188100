import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { baseStyles } from '.';

export const availableCardPaddings = ['default', 'collapsed'] as const;
type PliCardPadding = (typeof availableCardPaddings)[number];

export const availableCardBorders = ['default', 'none'] as const;
type PliCardBorder = (typeof availableCardBorders)[number];
export interface PliCardProps {
    padding: PliCardPadding;
    borderType: PliCardBorder;
}

@customElement('pli-card')
class PliCard extends LitElement implements PliCardProps {
    @property()
    padding: PliCardPadding = 'default';

    @property()
    borderType: PliCardBorder = 'none';

    static styles = [
        baseStyles,
        css`
            :host {
                --border-none: 0;
                --padding: var(--padding-default);
                --padding-collapsed: 0;
                --padding-default: var(--size-1-5);
                --negative-margin: calc(var(--padding) * -1);
            }
            .pli-card {
                border-radius: 0.5rem;
                box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 3px;
                background-color: var(--color-white);
                padding: var(--padding);
                height: 100%;
                border: var(--border);
            }
        `,
    ];
    render() {
        this.style.setProperty('--padding', `var(--padding-${this.padding})`);
        this.style.setProperty('--border', `var(--border-${this.borderType})`);

        return html`
            <div class="pli-card">
                <slot></slot>
            </div>
        `;
    }
}
