import { LitElement, PropertyValueMap, TemplateResult, css, html } from 'lit';
import { map } from 'lit/directives/map.js';
import { range } from 'lit/directives/range.js';

import './pli-skeleton';
import './pli-icon';
import { customElement, property, state } from 'lit/decorators.js';
import '../controllers/storage-order-controller';
import { StorageHandler } from '../storage-handler';
import './pli-text';
import { styles } from './styles';
import { PliIconProps } from './pli-icon';

type TableHeaderItem = {
    title: string;
    field?: string | null;
    columnSpan: PliTableColumn;
};

const availableSortOrders = ['asc', 'desc'] as const;
type SortOrder = (typeof availableSortOrders)[number];

const availableColumns = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] as const;
type PliTableColumn = (typeof availableColumns)[number];

export const defineHeaderItems = (
    input: Record<string, { sortField: string | null; columnSpan?: PliTableColumn }>,
): PliTableProps['headerItems'] => {
    const items = Object.entries(input).map(([key, value]) => ({
        title: key,
        field: value.sortField,
        columnSpan: (value.columnSpan as PliTableColumn) ?? 1,
    }));

    const totalColumns = items.reduce((total, current) => {
        return (total += current.columnSpan);
    }, 0);

    if (totalColumns > 12) {
        throw new Error(`Maximum column count allowed is 12. Total column count passed ${totalColumns}`);
    }
    return items;
};

export interface PliTableProps {
    headerItems: TableHeaderItem[];
    items: unknown[];
    sortOrderKey?: string;
    loading?: boolean;
    loadingRowsCount?: number;
    renderTemplate: (input: unknown) => TemplateResult;
}

@customElement('pli-table')
export class PliTable extends LitElement implements PliTableProps {
    static styles = [
        styles.base,
        styles.flex,
        styles.grid,
        css`
            :host {
                --total-columns: 12;
            }

            table {
                width: 100%;
                border-spacing: 0;
            }

            .table > tbody {
                vertical-align: inherit;
            }

            table th,
            table td {
                width: calc(100% / calc(var(--total-columns) / var(--columns)));
                border-bottom: 1px solid var(--bs-gray-400, var(--color-border));
                text-align: left;
            }

            table th,
            table td,
            button {
                height: var(--size-5);
            }

            table th:not(.collapsed),
            table td:not(.collapsed),
            button {
                padding: var(--size-1) var(--size-0-5);
            }

            table:not(.loading) tr:hover {
                background-color: var(--bs-gray-100);
            }

            button {
                width: 100%;
                display: flex;
                align-items: center;
                gap: var(--size-0-25);
                padding: var(--size-1) var(--size-0-5);
                background: none;
                border: 0;
            }

            .navigation {
                cursor: pointer;
            }
        `,
    ];
    @property({ type: Array })
    headerItems: TableHeaderItem[];

    @property({ type: Array })
    items: unknown[];

    @property()
    sortOrderKey?: string;

    @property({ type: Boolean })
    loading?: boolean;

    @property({ type: Number })
    loadingRowsCount?: number;

    @property()
    renderTemplate: (input: any) => TemplateResult;

    @state()
    _orders = [...availableSortOrders];

    @state()
    _field: string | null = null;

    @state()
    _order: (typeof availableSortOrders)[number] | null = null;

    @state()
    _columnsCount = 0;

    connectedCallback(): void {
        super.connectedCallback();
        this._columnsCount = this.headerItems?.length ?? 0;

        if (this.sortOrderKey) {
            this._setOrderFromStorage();
        }
    }

    private handleClick = (event: MouseEvent): void => {
        const tr = event.currentTarget as HTMLTableRowElement;
        const anchor = tr.querySelector('[data-link="navigate"]') as HTMLAnchorElement;
        const prevent = tr.querySelector('[data-link="prevent"]') as HTMLButtonElement;
        if (anchor && !prevent) {
            this.removeEventListeners();
            anchor.click();
        }
    };

    protected updated(): void {
        const trs = this.shadowRoot!.querySelectorAll('tr');
        trs.forEach((tr) => {
            tr.classList.add('navigation');
            tr.addEventListener('click', this.handleClick);
        });
    }

    protected removeEventListeners(): void {
        const trs = this.shadowRoot!.querySelectorAll('tr');
        trs.forEach((tr) => {
            tr.removeEventListener('click', this.handleClick);
        });
    }

    disconnectedCallback(): void {
        this.removeEventListeners();
        super.disconnectedCallback();
    }

    private _setOrderFromStorage() {
        const storedOrderItem = StorageHandler.getFromStorage(this.sortOrderKey);

        if (storedOrderItem !== null) {
            this._order = storedOrderItem.sortOrder as SortOrder;
            this._field = storedOrderItem.field;
        }
    }

    private _saveOrderToStorage() {
        const { _field, _order } = this;

        StorageHandler.saveToStorage(this.sortOrderKey, {
            field: _field,
            sortOrder: _order,
        });
    }

    private _getToggledSortOrder() {
        return this._orders.find((i) => i !== this._order) ?? this._orders[0];
    }

    private _shouldToggleOrder(field: string) {
        return field === this._field;
    }

    _clickHandler(field: string) {
        const shouldToggle = this._shouldToggleOrder(field);
        this._order = shouldToggle ? this._getToggledSortOrder() : this._orders[0];
        this._field = field;

        this._saveOrderToStorage();
        const event: PliTableSortEvent = new CustomEvent('sort', {
            composed: true,
            detail: {
                order: this._order,
                field,
            },
        });

        this.dispatchEvent(event);
    }

    _isActiveField = (field: string) => field === this._field;

    _loadingLayout() {
        const rows = this.loadingRowsCount;
        const columns = this.headerItems.map((item) => item.columnSpan);

        return html`
            <table class="loading">
                <thead>
                    ${columns.map(
                        (item) => html`
                            <th style="${this.getColumnCSS(item)}"><pli-skeleton .height=${16}></pli-skeleton></th>
                        `,
                    )}
                </thead>
                <tbody>
                    ${map(
                        range(rows),
                        () => html`
                            <tr>
                                ${columns.map(
                                    (item) =>
                                        html` <td>
                                            <pli-skeleton .height=${16}></pli-skeleton>
                                        </td>`,
                                )}
                            </tr>
                        `,
                    )}
                </tbody>
            </table>
        `;
    }

    _renderButton(item: TableHeaderItem) {
        const icon = (): PliIconProps['name'] => {
            if (!this._isActiveField(item.field)) return 'filter-left';

            switch (this._order) {
                case 'asc':
                    return 'sort-up';
                case 'desc':
                    return 'sort-down';
            }
        };
        return html`
            <button
                @click="${() => this._clickHandler(item.field)}"
                type="button"
                aria-label="Sort table by field: ${item.field}"
            >
                <pli-text variant="body"><strong>${item.title}</strong></pli-text>
                <pli-icon .name="${icon()}"></pli-icon>
            </button>
        `;
    }

    getColumnCSS = (index: number) => `--columns: ${index}`;

    renderTableHeaderItem(item: TableHeaderItem) {
        if (!item.field) {
            return html`<th style="${this.getColumnCSS(item.columnSpan)}">
                <pli-text variant="body"><strong>${item.title}</strong></pli-text>
            </th>`;
        }
        return html`<th style="${this.getColumnCSS(item.columnSpan)}" class="collapsed">
            ${this._renderButton(item)}
        </th>`;
    }

    render() {
        if (this.loading && Boolean(this._columnsCount)) {
            return this._loadingLayout();
        }

        return html`
            <table>
                <thead>
                    ${this.headerItems?.map((item) => this.renderTableHeaderItem(item))}
                </thead>
                <tbody>
                    ${this.renderItems()}
                </tbody>
            </table>
        `;
    }

    private renderItems(): unknown {
        if (!this.items) return null;
        return this.items.map((item) => this.renderTemplate(item));
    }
}

export type PliTableSortEvent = CustomEvent<{ order: SortOrder; field: string }>;
