import { LitElement, css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

import './pli-text';
import {
    PliRoundedElementProps,
    PliRoundedElementRadius,
    createRoundedElementClassnames,
} from './utils/create-rounded-element-classnames';
import { styles } from './styles';

export const availableVariants = ['primary', 'secondary', 'destructive', 'text'] as const;

export const availableSizes = ['lg', 'md', 'sm'] as const;
export const availableWidths = ['hug-content', 'full'] as const;
export const availableElements = ['a', 'button'] as const;
export const availableShapes = ['square', 'circle'] as const;

type PliButtonVariant = (typeof availableVariants)[number];
type PliButtonAsElement = (typeof availableElements)[number];
type PliButtonSize = (typeof availableSizes)[number];
type PliButtonWidth = (typeof availableWidths)[number];
type PliButtonShape = (typeof availableShapes)[number];

export type PliButtonProps = PliRoundedElementProps<{
    onClick: () => void;
    href?: string;
    as?: PliButtonAsElement;
    variant: PliButtonVariant;
    size: PliButtonSize;
    width: PliButtonWidth;
    disabled?: boolean;
    shape?: PliButtonShape;
}>;

@customElement('pli-button')
export class PliButton extends LitElement implements PliButtonProps {
    @property()
    onClick!: () => void;

    @property()
    href?: string;

    @property()
    as?: PliButtonAsElement = 'button';

    @property()
    variant: PliButtonVariant = 'primary';

    @property()
    size: PliButtonSize = 'md';

    @property()
    width: PliButtonWidth = 'hug-content';

    @property()
    justify: 'justify' | 'center' | 'space-between' = 'center';

    @property({ type: Boolean })
    disabled?: boolean;

    @property()
    shape?: PliButtonShape;

    @property()
    radiusTopRight?: PliRoundedElementRadius = 'md';

    @property()
    radiusBottomRight?: PliRoundedElementRadius = 'md';

    @property()
    radiusBottomLeft?: PliRoundedElementRadius = 'md';

    @property()
    radiusTopLeft?: PliRoundedElementRadius = 'md';

    @state()
    invalidSetup = () => this.as === 'a' && !this.href;

    static styles = [
        styles.base,
        styles.rounded,
        css`
            :host {
                --width-hug-content: auto;
                --width-full: 100%;

                --width: var(--width-hug-content);
                --border-default: 0px solid transparent;
                --border: var(--border-default);

                --padding: var(--padding-md);
                --background: var(--background-primary);
                --foreground: var(--foreground-primary);

                --background-primary: var(--button-background-primary);
                --foreground-primary: var(--color-white);

                --background-secondary: var(--color-white);
                --foreground-secondary: var(--color-black);
                --border-secondary: 1px solid var(--color-border);

                --background-destructive: var(--button-background-destructive);
                --foreground-destructive: var(--color-white);

                --background-disabled: var(--button-background-disabled);
                --foreground-disabled: var(--color-white);

                --background-text: rgba(0, 0, 0, 0);
                --foreground-text: var(--button-color-text);

                --padding-lg: var(--size-1) var(--size-1-5);
                --padding-md: var(--size-0-5) var(--size-0-75);
                --padding-sm: var(--size-0-25) var(--size-0-5);

                --aspect-1-1: 1 / 1;

                display: inline-flex;
                width: var(--width);
            }

            button {
                appearance: none;
            }

            button,
            a {
                border: var(--border);
                box-shadow: none;
                padding: var(--padding);
                background-color: var(--background);
                color: var(--foreground);
                width: var(--width);
                height: 100%;
                display: inline-flex;
                justify-content: var(--justify);
                align-items: center;
                gap: var(--size-0-25);
                aspect-ratio: var(--aspect-ratio);
            }

            button:not(:disabled):hover {
                background-color: var(--background-hover);
                color: var(--foreground-hover);
            }

            button:not(:disabled) {
                cursor: pointer;
            }

            button:disabled {
                opacity: 5;
            }

            .text {
                width: auto;
                background: none;
                color: var(--button-color-text);
            }

            ::slotted() {
                flex-shrink: 0;
            }
        `,
    ];

    connectedCallback(): void {
        super.connectedCallback();
        if (this.invalidSetup()) {
            throw new Error('When using <pli-button> as <a> it must have href property');
        }
    }

    private setStyle() {
        this.setupCoreStyles();
        this.setupHoverStyles();
        this.setupShapeStyles();
    }

    private setupShapeStyles() {
        if (!this.shape) return;

        this.style.setProperty('--padding', '0');
        this.style.setProperty('--width', 'var(--width-full)');
        this.style.setProperty('--aspect-ratio', 'var(--aspect-1-1)');
    }

    private setupCoreStyles() {
        if (this.disabled) {
            this.style.setProperty('--background', `var(--background-disabled`);
            this.style.setProperty('--foreground', `var(--foreground-disabled`);
        } else {
            this.style.setProperty('--background', `var(--background-${this.variant})`);
            this.style.setProperty('--foreground', `var(--foreground-${this.variant})`);
        }
        this.style.setProperty('--padding', `var(--padding-${this.size})`);
        this.style.setProperty('--width', `var(--width-${this.width})`);
        this.style.setProperty('--border', `var(--border-${this.variant})`);
        this.style.setProperty('--justify', this.justify);
    }

    private setupHoverStyles() {
        this.style.setProperty('--background-hover', `var(--button-background-${this.variant}-hover)`);
        this.style.setProperty('--foreground-hover', `var(--button-foreground-${this.variant}-hover)`);
    }

    _innerTemplate() {
        return html`
            <slot name="icon-left"></slot>
            <pli-text variant="body">
                <slot></slot>
            </pli-text>
            <slot name="icon-right"></slot>
        `;
    }

    render() {
        this.setStyle();
        const { radiusBottomLeft, radiusBottomRight, radiusTopLeft, radiusTopRight } = this;
        const { classNames: classes } =
            this.shape === 'circle'
                ? createRoundedElementClassnames({ radius: 'full' })
                : createRoundedElementClassnames({
                      radiusBottomLeft,
                      radiusBottomRight,
                      radiusTopLeft,
                      radiusTopRight,
                  });

        if (this.as === 'a' && this.href)
            return html` <a class="${classes}" href="${this.href}">${this._innerTemplate()}</a> `;

        return html`
            <button class="${classes}" ?disabled="${this.disabled}" role="button" @click="${this.onClick}">
                ${this._innerTemplate()}
            </button>
        `;
    }
}
