import { BarElement, BarController, Chart, CategoryScale, LinearScale } from 'chart.js';
import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { Ref, createRef, ref } from 'lit/directives/ref.js';

export interface PliBarChartProps {
    data: number[];
    labels: string[];
    graphLabel: string;
}

@customElement('pli-bar-chart')
class PliBarChart extends LitElement implements PliBarChartProps {
    static styles = [
        css`
            .canvas-wrapper {
                position: relative;
                aspect-ratio: 16 / 9;
            }

            canvas {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        `,
    ];
    @property({ type: Array })
    labels: string[];

    @property({ type: Array })
    data: number[];

    @property()
    graphLabel: string = '';

    _chart: Chart<'bar'>;
    _canvasRef: Ref<HTMLCanvasElement> = createRef();

    connectedCallback(): void {
        super.connectedCallback();
        Chart.register(BarElement);
        Chart.register(BarController);
        Chart.register(CategoryScale);
        Chart.register(LinearScale);
    }

    updated() {
        this.handleUpdated();
    }

    private handleUpdated() {
        const { labels, data } = this;
        this._chart.data.datasets.map((dataset) => {
            dataset.data = [...data];
        });
        this._chart.data.labels = [...labels];
        this._chart.update();
    }

    protected firstUpdated(): void {
        const { data, _canvasRef, labels, graphLabel } = this;

        const computedStyle = getComputedStyle(this);
        const barChartBgColor = computedStyle.getPropertyValue('--color-bar-chart-background');
        const borderColor = computedStyle.getPropertyValue('--color-border');

        let annotations = {};

        let datasets = [
            {
                label: graphLabel,
                data,
                parsing: {
                    xAxisKey: 'label',
                    yAxisKey: 'withdrawal',
                },
                borderColor: barChartBgColor,
                backgroundColor: barChartBgColor,
                order: 2,
            },
        ];

        this._chart = new Chart(_canvasRef.value, {
            type: 'bar',
            data: {
                labels,
                datasets: datasets,
            },
            options: {
                aspectRatio: 16 / 9,
                plugins: {
                    legend: {
                        position: 'top',
                    },
                    annotation: {
                        annotations: annotations,
                    },
                },
                scales: {
                    x: {
                        grid: {
                            drawOnChartArea: false,
                            tickLength: 0,
                        },
                    },
                    y: {
                        ticks: {
                            // forces step size to be 50 units
                            stepSize: 10,
                        },
                        suggestedMax: 10,

                        grid: {
                            tickLength: 0,
                            display: true,
                            borderDash: [5, 5],
                            drawTicks: true,
                            color: borderColor,
                            drawBorder: false,
                        },
                    },
                },
            },
        });
    }

    render() {
        const { _canvasRef: canvasRef } = this;
        return html`<div class="canvas-wrapper"><canvas ${ref(canvasRef)}></canvas></div>`;
    }
}
